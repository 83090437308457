function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { useCallback, useEffect, useSyncExternalStore } from "react";
import { ExternalStoreListeners } from "./ExternalStoreListeners";
export class SSRGlobalCache {
    invalidate() {
        var _this = this;
        return _async_to_generator(function*() {
            if (_this.refetch) {
                const result = yield _this.refetch();
                _this.current = result;
                _this.listeners.notify();
                return result;
            } else {
                throw new Error("No refetch function");
            }
        })();
    }
    update(value) {
        this.current = value;
        this.listeners.notify();
    }
    constructor(){
        this.listeners = new ExternalStoreListeners();
    }
}
export function useSSRFetch(cache, ssr, refetch, dependencies) {
    useEffect(()=>{
        cache.current = undefined;
        cache.refetch = refetch;
    }, dependencies);
    return useSyncExternalStore(cache.listeners.on, useCallback(()=>{
        var _cache;
        var _current;
        return (_current = (_cache = cache).current) != null ? _current : _cache.current = ssr;
    }, dependencies), useCallback(()=>{
        return ssr;
    }, dependencies));
}
